import "./App.css";
import React from "react";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {AppBar, Box, Button, Card, CardContent, TextField, Toolbar, Typography} from "@mui/material";

const LOGO_URL = 'https://picars.pl/logo.png';

export default function App() {
    const [firstName, setFirstName] = React.useState("");
    const [position, setPosition] = React.useState("");
    const [phoneNumber, setPhoneNumber] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [code, setCode] = React.useState("");
    const [additionalNote, setAdditionalNote] = React.useState("");
    const previewEl = React.useCallback(
        (node) => {
            if (node !== null) {
                setCode(node.innerHTML);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [firstName, position, phoneNumber, email, additionalNote]
    );

    const handleCopy = () => {
        alert("Kod jest w schowku!");
    };

    return (
        <div className="App">
            <Box sx={{flexGrow: 1}}>
                <AppBar position="static">
                    <Toolbar>
                        <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                            Generator sygnatur - picars.pl
                        </Typography>

                    </Toolbar>
                </AppBar>
            </Box>
            <Card variant="outlined" elevation={0}>
                <CardContent>
                    <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                        Wypełnij formularz
                    </Typography>

                    <div className="row">
                        <TextField id="standard-basic" label="Imie i nazwisko" variant="standard"
                                   fullWidth={true}
                                   value={firstName}
                                   onChange={(e) => setFirstName(e.target.value)}/>
                    </div>
                    <div className="row">
                        <TextField id="standard-basic" label="Stanowisko" variant="standard"
                                   fullWidth={true}
                                   value={position}
                                   onChange={(e) => setPosition(e.target.value)}/>
                    </div>
                    <div className="row">
                        <TextField id="standard-basic" label="Numer telefon" variant="standard"
                                   fullWidth={true}
                                   value={phoneNumber}
                                   onChange={(e) => setPhoneNumber(e.target.value)}/>
                    </div>
                    <div className="row">
                        <TextField id="standard-basic" label="Adres e-mail" variant="standard"
                                   fullWidth={true}
                                   value={email}
                                   onChange={(e) => setEmail(e.target.value)}/>
                    </div>

                    <div className="row">
                        <TextField id="standard-basic" label="Dodatkowe info" variant="standard"
                                   fullWidth={true}
                                   value={additionalNote}
                                   multiline={true}
                                   onChange={(e) => setAdditionalNote(e.target.value)}/>
                    </div>
                </CardContent>
            </Card>
            <br/>
            <Card variant="outlined" elevation={0}>
                <CardContent>
                    <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                        Podgląd sygnatury
                    </Typography>
                    <div>
                        <div className="preview" ref={previewEl}>
                            <div style={{lineHeight: "20px"}}>
            <span style={{fontStyle: "italic"}}>
              Pozdrawiam / Best regards / Mit freundlichen Grüßen
            </span>
                                <br/>
                                <br/>
                                <span style={{fontSize: "16px", fontWeight: "bold"}}>
              {firstName}
            </span>
                                <br/>
                                <span
                                    style={{
                                        fontSize: "14px",
                                        fontStyle: "italic"
                                    }}
                                >
              {position}
            </span>
                                <br/>
                                <br/>

                                {phoneNumber && (<>
                                    <span>
                <strong>mobile:</strong> {phoneNumber}
              </span><br/></>
                                )}
                                {email && (<>
                                    <span>
                                        <strong>email:</strong> {email}
              </span><br/></>
                                )}
                                <>
                                    <span>
                                        <strong>www:</strong> <a href={'https://picars.pl'}
                                                                 rel="noreferrer"
                                                                 target={'_blank'}>picars.pl</a>
              </span><br/><br/></>
                                <a href="https://picars.pl" target="_blank" rel="noreferrer">
                                    <img
                                        style={{width: "200px"}}
                                        alt="picars.pl"
                                        src={LOGO_URL}
                                    />
                                </a>
                                <br/> <br/>
                                <span>
              Picars Sp. z o.o. Sp. k. <br/>
              ul.Składowa 5, <br/> 27-200 Starachowice
            </span>{" "}
                                <br/><br/>
                                <span
                                    style={{
                                        fontSize: "14px",
                                    }}
                                >
                                NIP: 664 213 81 28 | REGON: 36790287600000 | KRS: 0000738510
                                </span>
                                {additionalNote && (
                                    <>
                                        <br/>
                                        <br/>
                                        <span style={{fontSize: "13px", fontStyle: "italic"}}>
                  {additionalNote}
                </span>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </CardContent>
            </Card>
            <br/>
            <Card variant="outlined" elevation={0}>
                <CardContent>
                    <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                        Kod źródłowy
                    </Typography>
                    <div className="previewEl">
                        <p>
                            Skopiuj poniszy kod:{" "}
                            <CopyToClipboard text={code} onCopy={handleCopy}>
                                <Button>Skopiuj do schowka</Button>
                            </CopyToClipboard>
                        </p>
                        <textarea
                            value={code}
                            disabled
                            style={{width: "100%", minHeight: "200px"}}
                        />
                    </div>
                </CardContent>
            </Card>
            <br/>
        </div>
    );
}
